import React from "react";
import { Turnstile } from "@marsidev/react-turnstile";

type Props = {
  token?: any;
  turnstileRef?: any;
};

function Captcha({ token, turnstileRef }: Props) {
  return (
    <Turnstile
      ref={turnstileRef}
      siteKey={`${process.env.TURNSTILE_SITE_KEY}`}
      onSuccess={token}
      onError={() => turnstileRef.current?.reset()}
      onExpire={() => turnstileRef.current?.reset()}
    />
  );
}

export default Captcha;
