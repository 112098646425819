import React, { Component } from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import SignInForm from "../components/SigninForm";
import Link from "next/link";
import axios from "axios";
import Swal from "sweetalert2";
import Image from "next/image";
import logo from "../public/static/images/logo.jpg";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import withAuth from "@/components/withAuth";
import router from "next/router";
import { login } from "@/features/login";
import { appDispatch, appSelector } from "@/store/hooks";

//Captcha
import Captcha from "@/components/turnstile/Captcha";
const Login = ({ ip }: any) => {
  const dispatch = appDispatch();
  const [username, setUsername] = React.useState<string | null>("");
  const [password, setPassword] = React.useState<string | null>("");
  const [userError, setUserError] = React.useState<Boolean>(false);
  const [passError, setPassError] = React.useState<Boolean>(false);
  // Captcha
  const [token, setToken] = React.useState<any>("");
  const turnstileRef: any = React.useRef();
  // Captcha

  const handleLogin = async () => {
    if (username && password) {
      const cfResponse = await axios.post("/api/turnstile", {
        token: token,
        ip: ip,
      });
      if (!cfResponse.data.status.success) {
        turnstileRef.current?.reset();
        Swal.fire("Error!", "", "error");
        setUserError(true);
        setPassError(true);
        return false;
      } else {
        const status = cfResponse.data.status.success;
        const response = await dispatch(login({ username, password, status }));
        console.log(response);
        if (response.meta.requestStatus == "fulfilled") {
          location.href = "/";
        } else {
          turnstileRef.current?.reset();
          Swal.fire("Error!", "Please check username or password", "error");
        }
      }
    } else {
      turnstileRef.current?.reset();
      Swal.fire("Error!", "Please check username or password", "error");
      setUserError(true);
      setPassError(true);
      return false;
    }
  };
  return (
    <div className="login">
      <div className="login_form shadow">
        <Image
          src={logo}
          alt="Okuma Service Portal"
          className="logo_login"
          width={200}
        />
        <LoginHeader title="Service Portal" />
        <Divider>Login</Divider>
        <SignInForm
          username={setUsername}
          password={setPassword}
          usernameError={userError}
          passwordError={passError}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: -3,
            marginBottom: 2,
          }}
        >
          <Box>
            <Captcha token={setToken} turnstileRef={turnstileRef} />
          </Box>
        </Box>

        <Button
          onClick={handleLogin}
          className="btn btn-primary px-5 py-2 text-center d-block mx-auto"
        >
          Login
        </Button>
      </div>
    </div>
  );
};

const LoginHeader = (props: any) => <h2 id="headerTitle">{props.title}</h2>;

export async function getServerSideProps(context: any) {
  const forwarded = context.req.headers["x-forwarded-for"];
  const ip = forwarded
    ? forwarded.split(/, /)[0]
    : context.req.connection.remoteAddress;
  return {
    props: {
      ip: ip,
    },
  };
}

export default withAuth(Login);
