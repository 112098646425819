import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";

const SigninForm = ({
  username,
  password,
  usernameError,
  passwordError,
}: any) => {
  function userError(usernameError: Boolean) {
    if (usernameError) {
      return {
        border: "red solid",
      };
    }
  }

  function passError(passwordError: Boolean) {
    if (passwordError) {
      return {
        border: "red solid",
      };
    }
  }

  return (
    <div className="formCenter">
      <form className="row formFields">
        <div className="formField col-12">
          <div className="row align-items-center">
            <div className="col-12 col-md-3 formField">
              <label className="formFieldLabel">User ID</label>
            </div>
            <div className="col-12 col-md-9 formField">
              <input
                type="text"
                id="ID"
                className="formFieldInput form-control"
                placeholder="Enter your User ID"
                name="ID"
                style={userError(usernameError)}
                onChange={(e) => {
                  username(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="formField col-12">
          <div className="row align-items-center">
            <div className="col-12 col-md-3 formField">
              <label className="formFieldLabel">Password</label>
            </div>
            <div className="col-12 col-md-9 formField">
              <input
                type="password"
                id="password"
                className="formFieldInput form-control"
                placeholder="Enter your password"
                name="password"
                style={passError(passwordError)}
                onChange={(e) => {
                  password(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="formField col-12 text-end">
          <Link href="/Forget" className="link">
            Forget Password <HelpOutlinedIcon />
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SigninForm;
